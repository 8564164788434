import React, {memo, useCallback, useEffect, useState} from 'react';
import styles from './PostUpdateComponent.module.scss';
import {ROW_ITEM_TYPES} from "../../constants/strings";
import UpdateProgressForm from "../../containers/StrategyPlanPage/ItemRowComponent/Components/UpdateProgressForm";
import {Menu, styled} from "@mui/material";
import TextareaFieldNoFormik from "../Shared/Inputs/TextareaFieldNoFormik";
import DefaultButton from "../Shared/Buttons/DefaultButton";

const StyledMenu = styled(Menu)`
  & .MuiPaper-root {
    border-radius: 8px;
  }

  & .MuiList-root {
    margin: 0;
    padding: 0;
  }
`;

const PostUpdateComponent = ({data, handleUpdate}) => {
  const [value, setValue] = useState(null);
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const onUpdateProgress = (values) => {
    if(values.actualValue){
      setValue(Number(values.actualValue));
    }
    setAnchorEl(null);
    handleUpdate(data?.type, values);
  };

  const cancelUpdate = () => {
    setAnchorEl(null);
    setValue(Number(data.actualValue));
  };

  const onProgressChange = (value) => {
    setValue(Number(value));
  };

  const handleClick = (e) => {
    switch (data?.type) {
      case ROW_ITEM_TYPES.GOAL:
      case ROW_ITEM_TYPES.PROJECT:
        handleUpdate(data?.type, {message: message, title: title});
        break;
      case ROW_ITEM_TYPES.KPI:
      case ROW_ITEM_TYPES.ACTION:
        if (!anchorEl) {
          setAnchorEl(anchorEl ? null : e.currentTarget);
        }
        break;
      default:
        console.log('no matching type selected');
    }
    setMessage('');
    setTitle('');
  };

  const setSliderValueFn = useCallback(() => {
    switch (data?.type) {
      case ROW_ITEM_TYPES.KPI:
        setValue(data?.actualValue);
        break;
      case ROW_ITEM_TYPES.GOAL:
      case ROW_ITEM_TYPES.PROJECT:
      case ROW_ITEM_TYPES.ACTION:
        setValue(data?.actualProgress);
        break;
      default:
        console.log('no matching type selected');
    }
  }, [data.type, data.actualProgress, data.actualValue]);

  const handleMessageChange = useCallback((value) => {
    setMessage(value);
  }, []);

  const handleTitleChange = useCallback((value) => {
    setTitle(value);
  }, []);

  const isDisabled = () => {
    if (data?.type === ROW_ITEM_TYPES.GOAL || data?.type === ROW_ITEM_TYPES.PROJECT) {
      return !message.length > 0 || !title.length > 0
    }

    return false;
  };

  useEffect(() => {
    if (data) {
      setSliderValueFn();
    }
  }, [data, setSliderValueFn]);

  const renderContent = () => {
    switch (data?.type) {
      case ROW_ITEM_TYPES.GOAL:
      case ROW_ITEM_TYPES.PROJECT:
        return (
          <div className={styles.textareaContainer}>
            <input
                placeholder={'Title'}
                value={title}
                onChange={(e) => handleTitleChange(e.target.value)}
                className={styles.inputWrapper}
                type="text"
            />
            <TextareaFieldNoFormik
              hideLabel
              placeholder={'Update'}
              value={message}
              onChange={handleMessageChange}
            />
          </div>
        );
      case ROW_ITEM_TYPES.KPI:
      case ROW_ITEM_TYPES.ACTION:
        return (
          <div>
            <StyledMenu open={Boolean(anchorEl)} anchorEl={anchorEl}>
              <UpdateProgressForm
                min={data.min}
                max={data.max}
                progress={value}
                status={data.status}
                expected={data.type === ROW_ITEM_TYPES.KPI ? data.expectedValue : data.expectedProgress}
                expectedUnit={data.type === ROW_ITEM_TYPES.KPI ? data.unit : 'percentage'}
                handleOnSubmit={onUpdateProgress}
                cancelUpdate={cancelUpdate}
                onProgressChange={onProgressChange}
                item={data}
              />
            </StyledMenu>
          </div>
        );
      default:
        console.log('no matching type selected');
    }
  };

  return (
    <div
      className={`
        ${styles.container}
        ${(data?.type === ROW_ITEM_TYPES.KPI || data?.type === ROW_ITEM_TYPES.ACTION) && styles.altContainer}
      `}
    >
      {renderContent()}
      <DefaultButton title={'Post Update'} height={46} onClick={handleClick} disabled={isDisabled()} />
    </div>
  );
};

export default memo(PostUpdateComponent);
