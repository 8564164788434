import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import PrivateRoute from './containers/PrivateRoute';
import PublicRoute from './containers/PublicRoute';
import LoginPage from './containers/LoginPage/Loadable';
import WorkspacePage from './containers/WorkspacePage/Loadable';
import {
    ACCEPT_INVITATION,
    ALIGNMENT,
    CONNECT_METRIC_WITH_KPI,
    CREATE_REPORT_WIDGET,
    CREATE_WIDGET,
    DASHBOARDS,
    FORGOT_PASSWORD,
    GROUP_SETTINGS,
    HOME,
    HOME_SEE_ALL_GOALS,
    HOME_SEE_ALL_PROJECTS_AND_ACTIONS,
    HOME_SEE_ALL_RECENT_UPDATES,
    HUB,
    INTEGRATIONS,
    INTEGRATIONS_GOOGLE_SHEET,
    INTEGRATIONS_JIRA, INTEGRATIONS_MICROSOFT_EXCEL,
    KANBAN_BOARD,
    LINKEDIN,
    LOGIN,
    MANAGE_CONNECTIONS,
    METRICS,
    MICROSOFT_SIGN_IN,
    NOTIFICATIONS,
    PERSPECTIVE_DASHBOARDS,
    PRICING,
    PROFILE,
    REDIRECT_TO_HOME,
    REGISTER, RELEASE_NOTES,
    RENDER_REPORT_SNAPSHOT,
    REPORT_SNAPSHOT,
    REPORTS,
    RESET_PASSWORD,
    SCORECARDS,
    SETTINGS,
    SINGLE_DASHBOARD,
    SINGLE_REPORT,
    STRATEGY_PLAN,
    STRATIFYAI,
    VERIFY_USER,
    VNC_ACADEMY, VNC_ACADEMY_VIEW,
    WEBINAR,
    WIDGET_LIST,
    WORKSHOPS,
    WORKSPACE,
    WORKSPACE_CREATE,
    WORKSPACE_TWO_FA,
} from './routesConstants';
import NotFoundPage from "./containers/NotFoundPage/Loadable";
import Home from "./containers/Home/Loadable";
import SeeAllItemsPage from "./containers/Home/SeeAllItemPage/Loadable";
import DashboardHomePage from "./containers/DashboardPage/Loadable";
import CreateWidgetPage from "./containers/DashboardPage/CreateWidgetPage/Loadable";
import ScorecardListPage from "./containers/ScorecardListPage/Loadable";
import Settings from "./containers/Settings/Loadable";
import GroupSettingsPage from "./containers/GroupSettingsPage/Loadable";
import {LinkedInCallback} from 'react-linkedin-login-oauth2';
import {DYNAMIC_TITLE, PAGES} from "./constants/pages";
import ForgotPasswordPage from "./containers/ForgotPasswordPage/Loadable";
import NewPasswordPage from "./containers/NewPasswordPage/Loadable";
import ProfileSettingsPage from "./containers/ProfileSettingsPage";
import StrategyPlanPage from "./containers/StrategyPlanPage/Loadable";
import WidgetListPage from "./containers/DashboardPage/WidgetListPage/Loadable";
import SingleDashboardPage from "./containers/DashboardPage/SingleDashboardPage/Loadable";
import MetricsHomePage from "./containers/MetricsPage/Loadable";
import AlignmentPage from "./containers/AlignmentPage/Loadable";
import CreateWorkspacePage from "./containers/WorkspacePage/CreateWorkspacePage";
import ConnectMetricWithKpiPage from "./containers/MetricsPage/ConnectMetricWithKpiPage/Loadable";
import HubPage from "./containers/HubPage/Loadable";
import StratifyAIHomePage from './containers/StratifyAI/Loadable';
import VncAcademyPage from "./containers/VncAcademyPage/Loadable";
import WebinarPage from "./containers/WebinarPage/Loadable";
import WorkshopsPage from "./containers/WorkshopsPage/Loadable";
import PerspectiveDashboardPage from "./containers/PerspectiveDashboardPage/Loadable";
import ReportsHomePage from "./containers/ReportsPage/Loadable";
import IntegrationsPage from "./containers/IntegrationsPage/Loadable";
import ManageConnectionsPage from "./containers/ManageConnectionsPage/Loadable";
import SingleReportPage from "./containers/ReportsPage/SingleReportPage/Loadable";
import VerifyUserPage from "./containers/VerifyUser/Loadable";
import RenderReportSnapshotPage from "./containers/RenderReportSnapshotPage/Loadable";
import GoogleSheetsIntegrationPage from "./containers/IntegrationsConnectingPage/GoogleSheetsIntegrationPage/Loadable";
import NotificationsPage from "./containers/NotificationsPage/Loadable";
import TwoFaPage from "./containers/WorkspacePage/TwoFaPage/Loadable";
import PricingPage from "./containers/PricingPage/Loadable";
import MicrosoftSignIn from "./containers/LoginPage/MicrosoftSignIn/Loadable";
import JiraIntegrationPage from "./containers/IntegrationsConnectingPage/JiraIntegrationPage/Loadable";
import {useSelector} from "react-redux";
import HybridRoute from "./containers/HybridRoute";
import {terminologySelector} from "./store/auth/selectors";
import RegisterPage from "./containers/RegisterPage/Loadable";
import TrainingCategoryPage from "./containers/VncAcademyPage/TrainingCategoryPage/Loadable";
import MicrosoftExcelIntegrationPage from "./containers/IntegrationsConnectingPage/MicrosoftExcelIntegrationPage/Loadable";
import KanbanBoardPage from "./containers/KanbanBoardPage/Loadable";
import ReleaseNotesPage from "./containers/ReleaseNotesPage/Loadable";

export default function Routes({user}) {
    const terminology = useSelector(terminologySelector());

    return (
        <Switch>
            <PublicRoute user={user} exact path={LOGIN} component={LoginPage}/>
            <PublicRoute user={user} exact path={REGISTER} component={RegisterPage}/>
            <PublicRoute user={user} exact path={MICROSOFT_SIGN_IN} component={MicrosoftSignIn}/>
            <PublicRoute user={user} exact path={VERIFY_USER} component={VerifyUserPage}/>
            <HybridRoute user={user} exact path={ACCEPT_INVITATION} component={VerifyUserPage}/>
            <HybridRoute user={user} exact path={REDIRECT_TO_HOME} component={VerifyUserPage}/>
            <PublicRoute exact path={INTEGRATIONS_GOOGLE_SHEET} component={GoogleSheetsIntegrationPage}/>
            <PublicRoute exact path={INTEGRATIONS_JIRA} component={JiraIntegrationPage}/>
            <PublicRoute exact path={INTEGRATIONS_MICROSOFT_EXCEL} component={MicrosoftExcelIntegrationPage}/>
            <PrivateRoute user={user} exact path={WORKSPACE} component={WorkspacePage} isWorkspace={true}/>
            <PrivateRoute user={user} exact path={WORKSPACE_TWO_FA} component={TwoFaPage} isWorkspace={true}/>
            <PrivateRoute user={user} exact path={WORKSPACE_CREATE} component={CreateWorkspacePage} isWorkspace={true}/>
            <PublicRoute user={user} exact path={FORGOT_PASSWORD} component={ForgotPasswordPage}/>
            <PublicRoute user={user} exact path={RESET_PASSWORD} component={NewPasswordPage}/>
            <PrivateRoute user={user} exact path={PERSPECTIVE_DASHBOARDS} component={PerspectiveDashboardPage}
                          title={DYNAMIC_TITLE({terminology}).PERSPECTIVE_DASHBOARD}/>
            <PrivateRoute user={user} exact path={HOME} component={Home} title={PAGES.HOME}/>
            <PrivateRoute user={user} exact path={PRICING} component={PricingPage} title={PAGES.PRICING}/>
            <PrivateRoute user={user} exact path={HOME_SEE_ALL_GOALS} component={SeeAllItemsPage}
                          title={DYNAMIC_TITLE({terminology}).UPCOMING_GOALS} />
            <PrivateRoute user={user} exact path={HOME_SEE_ALL_PROJECTS_AND_ACTIONS} component={SeeAllItemsPage}
                          title={DYNAMIC_TITLE({terminology}).UPCOMING_PROJECTS_AND_ACTIONS} />
            <PrivateRoute user={user} exact path={HOME_SEE_ALL_RECENT_UPDATES} component={SeeAllItemsPage}
                          title={PAGES.RECENT_UPDATES}/>
            <PrivateRoute user={user} exact path={DASHBOARDS} component={DashboardHomePage} title={PAGES.DASHBOARDS}/>
            <PrivateRoute user={user} exact path={METRICS} component={MetricsHomePage} title={PAGES.METRICS}/>
            <PrivateRoute user={user} exact path={CONNECT_METRIC_WITH_KPI} component={ConnectMetricWithKpiPage}
                          title={DYNAMIC_TITLE({terminology}).CONNECT_METRIC_WITH_KPI}/>
            <PrivateRoute user={user} exact path={STRATIFYAI} component={StratifyAIHomePage} title={DYNAMIC_TITLE({terminology}).AIGen}/>
            <PrivateRoute user={user} exact path={CREATE_WIDGET} component={CreateWidgetPage}
                          title={PAGES.CREATE_WIDGET}/>
            <PrivateRoute user={user} exact path={WIDGET_LIST} component={WidgetListPage} title={PAGES.WIDGET_LIST}/>
            <PrivateRoute user={user} exact path={STRATEGY_PLAN} component={StrategyPlanPage} title={DYNAMIC_TITLE({terminology}).STRATEGY_PLAN}/>
            <PrivateRoute user={user} exact path={SETTINGS} component={Settings} title={PAGES.SETTINGS}/>
            <PrivateRoute user={user} exact path={PROFILE} component={ProfileSettingsPage}
                          title={PAGES.PROFILE_SETTINGS}/>
            <PrivateRoute user={user} exact path={SCORECARDS} component={ScorecardListPage} title={DYNAMIC_TITLE({terminology}).SCORECARDS}/>
            <PrivateRoute user={user} exact path={GROUP_SETTINGS} component={GroupSettingsPage}
                          title={PAGES.GROUP_SETTINGS}/>
            <PrivateRoute user={user} exact path={SINGLE_DASHBOARD} component={SingleDashboardPage}
                          title={PAGES.DASHBOARDS}/>
            <PrivateRoute user={user} exact path={HUB} component={HubPage} title={PAGES.HUB}/>
            <PrivateRoute user={user} exact path={ALIGNMENT} component={AlignmentPage} title={PAGES.ALIGNMENT_MAP}/>
            <PrivateRoute user={user} exact path={VNC_ACADEMY} component={VncAcademyPage} title={PAGES.VNC_ACADEMY}/>
            <PrivateRoute user={user} exact path={VNC_ACADEMY_VIEW} component={TrainingCategoryPage} title={PAGES.VNC_ACADEMY}/>
            <PrivateRoute user={user} exact path={WEBINAR} component={WebinarPage} title={PAGES.WEBINAR}/>
            <PrivateRoute user={user} exact path={WORKSHOPS} component={WorkshopsPage} title={PAGES.WORKSHOPS}/>
            <PrivateRoute user={user} exact path={REPORTS} component={ReportsHomePage} title={PAGES.REPORTS}/>
            <PrivateRoute user={user} exact path={INTEGRATIONS} component={IntegrationsPage}
                          title={PAGES.INTEGRATIONS}/>
            <PrivateRoute user={user} exact path={MANAGE_CONNECTIONS} component={ManageConnectionsPage}
                          title={PAGES.MANAGE_CONNECTIONS}/>
            <PrivateRoute user={user} exact path={SINGLE_REPORT} component={SingleReportPage}
                          title={PAGES.SINGLE_REPORT}/>
            <PrivateRoute user={user} exact path={CREATE_REPORT_WIDGET} component={CreateWidgetPage}
                          title={PAGES.CREATE_WIDGET}/>
            <PrivateRoute user={user} exact path={REPORT_SNAPSHOT} component={SingleReportPage}
                          title={PAGES.REPORT_SNAPSHOT}/>
            <PrivateRoute user={user} exact path={NOTIFICATIONS} component={NotificationsPage}
                          title={PAGES.NOTIFICATIONS}/>
            <PrivateRoute user={user} exact path={KANBAN_BOARD} component={KanbanBoardPage}
                          title={PAGES.KANBAN_BOARD}/>
            <PublicRoute exact path={RENDER_REPORT_SNAPSHOT} component={RenderReportSnapshotPage}
                         title={PAGES.REPORT_SNAPSHOT}/>
            <PrivateRoute user={user} exact path={RELEASE_NOTES} component={ReleaseNotesPage}
                          title={PAGES.RELEASE_NOTES}/>
            <Route exact path={LINKEDIN} component={LinkedInCallback}/>
            <Route exact path="/">
                <Redirect to={HOME}/>
            </Route>
            <Route path="*" component={NotFoundPage}/>
        </Switch>
    );
}
