import {defaultFormatDate, roundToDecimalFormat} from "./format";
import dayjs from "dayjs";
import {formatUnitAndValue, getItemTypeTerminology} from "./perspective";
import {
    CHART_TYPES,
    GOALS_HEALTH,
    GRAPH_TYPES,
    ITEM_HEALTH_STATUS,
    ItemHealth,
    ROW_ITEM_TYPES
} from "../constants/strings";
import HealthOfYourPlan from "../components/Graphs/HealthOfYourPlan";
import React from "react";
import CompareMultipleKpis from "../components/Graphs/CompareMultipleKpis";
import CurrentStateOfKpi from "../components/Graphs/CurrentStateOfKpi";
import {ReactComponent as FinancialGraphIcon} from '../assets/icons/graph-financial.svg';
import {ReactComponent as PieChartIcon} from '../assets/icons/pie-chart.svg';
import {ReactComponent as BarChartIcon} from '../assets/icons/bar-chart.svg';
import {ReactComponent as BarsChartIcon} from '../assets/icons/bars-chart.svg';
import CompareKpisDifferentUnit from "../components/Graphs/CompareKpisDifferentUnit";
import RiskDistributionScatterPlot from "../components/Graphs/RiskDistributionScatterPlot";
import CompareMultipleKpisByOwner from "../components/Graphs/CompareMultipleKpisByOwner";
import PerformanceOfKpis from "../components/Graphs/PerformanceOfKpis";
import ChartComponent from "../components/Shared/ChartWithProgressComponent/ChartComponent/ChartComponent";
import InitiativeTable from "../components/Graphs/InitiativeTable";
import {COLORS} from "../constants/colors";
import MultiplePlansSnapshot from "../components/Graphs/MultiplePlansSnapshot";
import styles from '../style.module.scss';
import GroupsTable from "../components/Graphs/GroupsTable";
import GoalsTable from "../components/Graphs/GoalsTable";
import KpiTable from "../components/Graphs/KpiTable";
import {getSource, getSourceType} from "./helper";


export const PeriodKeyEnums = {
    last7Days: 'last7Days',
    last14Days: 'last14Days',
    lastMonth: 'lastMonth',
    last3Months: 'last3Months',
    last6Months: 'last6Months',
    last1Year: 'last1Year',
    last3Years: 'last3Years',
    last5Years: 'last5Years',
}

export const PeriodTypeEnums = {
    last7Days: 'Last 7 days',
    last14Days: 'Last 14 days',
    lastMonth: 'Last month',
    last3Months: 'Last 3 months',
    last6Months: 'Last 6 months',
    last1Year: 'Last 1 year',
    last3Years: 'Last 3 years',
    last5Years: 'Last 5 years',
}

export const calcStartDateByPeriodType = (type) => {
    switch (type) {
    case PeriodTypeEnums.last7Days:
        return defaultFormatDate(dayjs().subtract(7, 'day'));
    case PeriodTypeEnums.last14Days:
        return defaultFormatDate(dayjs().subtract(14, 'day'));
    case PeriodTypeEnums.lastMonth:
        return defaultFormatDate(dayjs().subtract(1, 'month'));
    case PeriodTypeEnums.last3Months:
        return defaultFormatDate(dayjs().subtract(3, 'month'));
    case PeriodTypeEnums.last6Months:
        return defaultFormatDate(dayjs().subtract(6, 'month'));
    case PeriodTypeEnums.last1Year:
        return defaultFormatDate(dayjs().subtract(1, 'year'));
    case PeriodTypeEnums.last3Years:
        return defaultFormatDate(dayjs().subtract(3, 'year'));
    case PeriodTypeEnums.last5Years:
        return defaultFormatDate(dayjs().subtract(5, 'year'));
    default: return console.log('no matching type selected')
    }
}

export const calcDateRangeByPeriodType = (type) => {
    switch (type) {
        case PeriodTypeEnums.last7Days:
            return defaultFormatDate(dayjs().subtract(7, 'day')) + ' - ' + defaultFormatDate(dayjs());
        case PeriodTypeEnums.last14Days:
            return defaultFormatDate(dayjs().subtract(14, 'day')) + ' - ' + defaultFormatDate(dayjs());
        case PeriodTypeEnums.lastMonth:
            return defaultFormatDate(dayjs().subtract(1, 'month')) + ' - ' + defaultFormatDate(dayjs());
        case PeriodTypeEnums.last3Months:
            return defaultFormatDate(dayjs().subtract(3, 'month')) + ' - ' + defaultFormatDate(dayjs());
        case PeriodTypeEnums.last6Months:
            return defaultFormatDate(dayjs().subtract(6, 'month')) + ' - ' + defaultFormatDate(dayjs());
        case PeriodTypeEnums.last1Year:
            return defaultFormatDate(dayjs().subtract(1, 'year')) + ' - ' + defaultFormatDate(dayjs());
        case PeriodTypeEnums.last3Years:
            return defaultFormatDate(dayjs().subtract(3, 'year')) + ' - ' + defaultFormatDate(dayjs());
        case PeriodTypeEnums.last5Years:
            return defaultFormatDate(dayjs().subtract(5, 'year')) + ' - ' + defaultFormatDate(dayjs());
        default: return console.log('no matching type selected')
    }
}

export const calcDateRangeByPeriodKeyName = (type) => {
    switch (type) {
        case PeriodKeyEnums.last7Days:
            return periodSelectOptions[0];
        case PeriodKeyEnums.last14Days:
            return periodSelectOptions[1];
        case PeriodKeyEnums.lastMonth:
            return periodSelectOptions[2];
        case PeriodKeyEnums.last3Months:
            return periodSelectOptions[3];
        case PeriodKeyEnums.last6Months:
            return periodSelectOptions[4];
        case PeriodKeyEnums.last1Year:
            return periodSelectOptions[5];
        case PeriodKeyEnums.last3Years:
            return periodSelectOptions[6];
        case PeriodKeyEnums.last5Years:
            return periodSelectOptions[7];
        default: return console.log('no matching type selected')
    }
}

export const periodSelectOptions = [
    {id: 1, name: PeriodTypeEnums.last7Days, addition: '(daily)', subtitle: calcDateRangeByPeriodType(PeriodTypeEnums.last7Days), value: Object.keys(PeriodTypeEnums)[0]},
    {id: 2, name: PeriodTypeEnums.last14Days, addition: '(daily)', subtitle: calcDateRangeByPeriodType(PeriodTypeEnums.last14Days), value: Object.keys(PeriodTypeEnums)[1]},
    {id: 3, name: PeriodTypeEnums.lastMonth, addition: '(weekly)', subtitle: calcDateRangeByPeriodType(PeriodTypeEnums.lastMonth), value: Object.keys(PeriodTypeEnums)[2]},
    {id: 4, name: PeriodTypeEnums.last3Months, addition: '(weekly)', subtitle: calcDateRangeByPeriodType(PeriodTypeEnums.last3Months), value: Object.keys(PeriodTypeEnums)[3]},
    {id: 5, name: PeriodTypeEnums.last6Months, addition: '(monthly)', subtitle: calcDateRangeByPeriodType(PeriodTypeEnums.last6Months), value: Object.keys(PeriodTypeEnums)[4]},
    {id: 6, name: PeriodTypeEnums.last1Year, addition: '(monthly)', subtitle: calcDateRangeByPeriodType(PeriodTypeEnums.last1Year), value: Object.keys(PeriodTypeEnums)[5]},
    {id: 7, name: PeriodTypeEnums.last3Years, addition: '(quarterly)', subtitle: calcDateRangeByPeriodType(PeriodTypeEnums.last3Years), value: Object.keys(PeriodTypeEnums)[6]},
    {id: 8, name: PeriodTypeEnums.last5Years, addition: '(quarterly)', subtitle: calcDateRangeByPeriodType(PeriodTypeEnums.last5Years), value: Object.keys(PeriodTypeEnums)[7]},
];

export const formatPeriodRanges = (periodType) => {
    switch (periodType) {
        case Object.keys(PeriodTypeEnums)[0]:
            return 'Since 7 Days';
        case Object.keys(PeriodTypeEnums)[1]:
            return 'Since 14 Days';
        case Object.keys(PeriodTypeEnums)[2]:
            return 'Since last month';
        case Object.keys(PeriodTypeEnums)[3]:
            return 'Since 3 Months';
        case Object.keys(PeriodTypeEnums)[4]:
            return 'Since 6 Months';
        case Object.keys(PeriodTypeEnums)[5]:
            return 'Since last Year';
        case Object.keys(PeriodTypeEnums)[6]:
            return 'Since 3 Years';
        case Object.keys(PeriodTypeEnums)[7]:
            return 'Since 5 Years';
        default:
            return 'Since selected period'
    }
}

export const formatNumberValueWithUnit = (value, unit) => {
    switch (unit) {
        case 'dollar':
        case 'pound':
            if (value > 999.99 && value < 1000000) {
                return formatUnitAndValue(unit, roundToDecimalFormat((value / 1000))) + 'k';
            } else if (value >= 1000000) {
                return formatUnitAndValue(unit, roundToDecimalFormat((value / 1000000))) + 'M';
            } else {
                return formatUnitAndValue(unit, roundToDecimalFormat(value));
            }
        default:
            return formatUnitAndValue(unit, roundToDecimalFormat(value));
    }
}

export const formatUpdates = (arr) => {
    let sortedDates = [...arr];

    sortedDates.sort((date1, date2) => date1.id - date2.id);

    const uniqueDatesMap = sortedDates.reduce((map, entry) => {
        const date = entry.createdAt.split('T')[0];

        map.set(date, entry);
        return map;
    }, new Map());

    return Array.from(uniqueDatesMap.values())
}

const getArrFromObj = (obj) => {
    let arr = [];
    Object.values(obj).map((item) => {
        arr = [...arr, ...item];
    });
    return arr
}

export const renderWidgetItem = ({
                                     item,
                                     onChartClick = () => {},
                                     showKpiMenu,
                                     onKpiMenuPress,
                                     fromReport,
                                     terminology,
                                     hideShadow,
                                 }) => {
    switch (item.chartType) {
        case CHART_TYPES.HEALTH_OF_YOUR_PLAN:
            return (
                <HealthOfYourPlan data={item.chartData} onChartClick={onChartClick}/>
            );
        case CHART_TYPES.COMPARE_MULTIPLE_KPIS:
            return (
                <CompareMultipleKpis data={item.chartData} type={ROW_ITEM_TYPES.KPI} onChartClick={onChartClick}/>
            );
        case CHART_TYPES.CURRENT_KPI_STATE:
            return (
                <CurrentStateOfKpi
                    data={item.chartData}
                    item={item}
                    onChartClick={onChartClick}
                    showMenu={showKpiMenu}
                    onActionPress={onKpiMenuPress}
                    hideShadow={hideShadow}
                />
            );
        case CHART_TYPES.COMPARE_KPIS_DIFFERENT_UNIT:
            return (
                <CompareKpisDifferentUnit data={item.chartData} onChartClick={onChartClick}/>
            );
        case CHART_TYPES.COMPARE_KPIS_DIFFERENT_OWNER:
            return (
                <CompareMultipleKpisByOwner fromReport={fromReport} data={item.chartData} onChartClick={onChartClick}/>
            );
        case CHART_TYPES.RISK_DISTRIBUTION:
            return (
                <RiskDistributionScatterPlot data={item.chartData} />
            );
        case CHART_TYPES.COMPARE_MULTIPLE_GOALS:
            return (
                <CompareMultipleKpis data={item.chartData} label={`Avg completion of ${terminology.goal}s (%)`}
                                     type={ROW_ITEM_TYPES.GOAL} onChartClick={onChartClick}/>
            );
        case CHART_TYPES.PERFORMANCE_OF_KPIS:
            return (
                <PerformanceOfKpis data={item.chartData} onChartClick={onChartClick} />
            );
        case CHART_TYPES.SINGLE_KPI_PERFORMANCE_WIDGET:
            return (
                <ChartComponent data={item?.chartData && formatUpdates(item?.chartData[0]?.data)} item={item?.chartData &&  item?.chartData[0]?.kpi} onChartClick={onChartClick} />
            );
        case CHART_TYPES.INITIATIVE_TABLE:
            return (
                <div className={`${!fromReport && styles.height100}`}>
                    <div className={styles.tableName}>
                        <p>{`Owner: ${item?.owner?.name ? item?.owner?.name : item?.owner?.email}`}</p>
                    </div>
                    <InitiativeTable
                      data={getArrFromObj(item?.itemsForTable)
                        .sort(function(a,b){return new Date(b.updatedAt) - new Date(a.updatedAt)})}
                      fromReport={fromReport}
                    />
                </div>
              );
        case CHART_TYPES.MULTIPLE_PLANS_SNAPSHOT:
            return (
                <MultiplePlansSnapshot data={item?.scorecardsForTable} onChartClick={onChartClick} fromReport={fromReport} />
            );
        case CHART_TYPES.GROUPS_TABLE:
            return (
                <GroupsTable groups={item?.ownersForTable} onChartClick={onChartClick} />
            );
        case CHART_TYPES.GOALS_TABLE:
            return (
                <GoalsTable plans={item?.goalsForTable} onChartClick={onChartClick} fromReport={fromReport} />
            );
        case CHART_TYPES.KPI_TABLE:
            return (
                <div className={`${!fromReport && styles.height100}`}>
                    <div className={styles.tableName}>
                        <p>{`${getItemTypeTerminology({type: getSourceType(getSource(item)?.sourceType), terminology})}: ${getSource(item)?.selectedSource?.name}`}</p>
                    </div>
                    <KpiTable data={item?.kpisForTable} fromReport={fromReport}/>
                </div>
            );
        default:
            console.log('no matching type selected');
            return (
                <div/>
            );
    }
};

export const getChartTypePath = (type) => {
    switch (type) {
        case CHART_TYPES.HEALTH_OF_YOUR_PLAN:
            return GRAPH_TYPES.HEALTH_OF_YOUR_PLAN;
        case CHART_TYPES.CURRENT_KPI_STATE:
            return GRAPH_TYPES.CURRENT_STATE_OF_KPI;
        case CHART_TYPES.COMPARE_MULTIPLE_KPIS:
            return GRAPH_TYPES.COMPARE_MULTIPLE_KPIS;
        case CHART_TYPES.COMPARE_KPIS_DIFFERENT_UNIT:
            return GRAPH_TYPES.COMPARE_KPIS_DIFFERENT_UNIT;
        case CHART_TYPES.COMPARE_KPIS_DIFFERENT_OWNER:
            return GRAPH_TYPES.COMPARE_MULTIPLE_KPIS_BY_OWNER;
        case CHART_TYPES.RISK_DISTRIBUTION:
            return GRAPH_TYPES.RISK_DISTRIBUTION_SCATTER_PLOT;
        case CHART_TYPES.COMPARE_MULTIPLE_GOALS:
            return GRAPH_TYPES.COMPARE_MULTIPLE_GOALS;
        case CHART_TYPES.PERFORMANCE_OF_KPIS:
            return GRAPH_TYPES.PERFORMANCE_OF_KPIS;
        case CHART_TYPES.SINGLE_KPI_PERFORMANCE_WIDGET:
            return GRAPH_TYPES.SINGLE_KPI_PERFORMANCE;
        case CHART_TYPES.INITIATIVE_TABLE:
            return GRAPH_TYPES.INITIATIVE_TABLE;
        case CHART_TYPES.MULTIPLE_PLANS_SNAPSHOT:
            return GRAPH_TYPES.MULTIPLE_PLANS_SNAPSHOT;
        case CHART_TYPES.GROUPS_TABLE:
            return GRAPH_TYPES.GROUPS_TABLE;
        case CHART_TYPES.GOALS_TABLE:
            return GRAPH_TYPES.GOALS_TABLE;
        case CHART_TYPES.KPI_TABLE:
            return GRAPH_TYPES.KPI_TABLE;
        default:
            console.log('no matching type selected');
            // TODO - Change after implementation of all graphs and charts
            return GRAPH_TYPES.HEALTH_OF_YOUR_PLAN;
    }
}

export const getWidgetPlaceholderIcon = (order) => {
    switch (order) {
        case 2:
            return (
                <BarsChartIcon/>
            );
        case 3:
            return (
                <PieChartIcon/>
            );
        case 4:
            return (
                <BarChartIcon/>
            );
        default:
            return (
                <FinancialGraphIcon/>
            );
    }
};

export const formatGraphNumber = (value) => {
    let transformedValue;
    switch (true) {
        case value >= 1e9:
            transformedValue = (value / 1e9).toFixed(value % 1e9 !== 0 ? 1 : 0);
            return transformedValue.endsWith('.0') ? transformedValue.slice(0, -2) + 'B' : transformedValue + 'B';
        case value >= 1e6:
            transformedValue = (value / 1e6).toFixed(value % 1e6 !== 0 ? 1 : 0);
            return transformedValue.endsWith('.0') ? transformedValue.slice(0, -2) + 'M' : transformedValue + 'M';
        case value >= 1e3:
            transformedValue = (value / 1e3).toFixed(value % 1e3 !== 0 ? 1 : 0);
            return transformedValue.endsWith('.0') ? transformedValue.slice(0, -2) + 'k' : transformedValue + 'k';
        default:
            return value?.toString();
    }
};

export const getRandomColor = () => {
    const randomR = Math.floor(Math.random() * 256);
    const randomG = Math.floor(Math.random() * 256);
    const randomB = Math.floor(Math.random() * 256);
    return `#${randomR.toString(16).padStart(2, '0')}${randomG.toString(16).padStart(2, '0')}${randomB.toString(16).padStart(2, '0')}`;
};

export const getRandomColors = (wantedNumberOfColors) => {
    const hardcodedColors = [
        COLORS.randomGreen,
        COLORS.randomYellow,
        COLORS.randomBlue,
        COLORS.randomOrange,
        COLORS.randomTurquoise,
        COLORS.randomPurple,
        COLORS.randomRed,
        COLORS.randomDarkGreen,
        COLORS.randomDarkBlue,
        COLORS.randomLightBlue,
    ];

    const randomColors = [];

    if (wantedNumberOfColors <= 10) {
        for (let i = 0; i < wantedNumberOfColors; i++) {
            randomColors.push(hardcodedColors[i]);
        }
    } else {
        randomColors.push(...hardcodedColors);
        for (let i = 10; i < wantedNumberOfColors; i++) {
            const randomR = Math.floor(Math.random() * 256);
            const randomG = Math.floor(Math.random() * 256);
            const randomB = Math.floor(Math.random() * 256);
            randomColors.push(`#${randomR.toString(16).padStart(2, '0')}${randomG.toString(16).padStart(2, '0')}${randomB.toString(16).padStart(2, '0')}`);
        }
    }

    return randomColors;
};

export const getItemScorecard = (item) => {
    switch (item?.type) {
        case ROW_ITEM_TYPES.GOAL:
            return item?.perspective?.scorecard;
        case ROW_ITEM_TYPES.KPI:
        case ROW_ITEM_TYPES.PROJECT:
            return item?.goal?.perspective?.scorecard;
        case ROW_ITEM_TYPES.ACTION:
            if (item?.goal) {
                return item?.goal?.perspective?.scorecard;
            } else {
                return item?.project?.goal?.perspective?.scorecard;
            }
        default:
            console.log('no matching type selected');
    }
};

export const itemHealthTransformer = (value) => {
    switch (value) {
        case ITEM_HEALTH_STATUS.AT_RISK:
            return ItemHealth.AT_RISK;
        case ITEM_HEALTH_STATUS.LATE:
            return ItemHealth.LATE;
        case ITEM_HEALTH_STATUS.ON_TRACK:
            return ItemHealth.ON_TRACK;
        case ITEM_HEALTH_STATUS.NOT_STARTED:
            return ItemHealth.NOT_STARTED;
        default:
            console.log('no matching case');
    }
};

export const getHealthStyle = (health) => {
    switch (health) {
        case GOALS_HEALTH.LATE:
            return <div className={styles.healthStyleDiv} style={{background: COLORS.progressBarLightRed}}>
                <p className={styles.getHealthStyleText}>{GOALS_HEALTH.LATE}</p>
            </div>
        case GOALS_HEALTH.AT_RISK:
            return <div className={styles.healthStyleDiv} style={{background: COLORS.brightDarkerOrange}}>
                <p className={styles.getHealthStyleTextAtRisk}>{GOALS_HEALTH.AT_RISK}</p>
            </div>
        case GOALS_HEALTH.ACHIEVED:
            return <div className={styles.healthStyleDiv} style={{background: COLORS.progressBarGreen}}>
                <p className={styles.getHealthStyleText}>{GOALS_HEALTH.ACHIEVED}</p>
            </div>
        case GOALS_HEALTH.EXCEEDED:
            return <div className={styles.healthStyleDiv} style={{background: COLORS.progressBarLightRed}}>
                <p className={styles.getHealthStyleText}>{GOALS_HEALTH.EXCEEDED}</p>
            </div>
        case GOALS_HEALTH.ON_TRACK:
            return <div className={styles.healthStyleDiv} style={{background: COLORS.lightBlue}}>
                <p className={styles.getHealthStyleText}>{GOALS_HEALTH.ON_TRACK}</p>
            </div>
        default: return <div>

        </div>
    }
}
