import React, {useCallback, useEffect, useRef, useState} from 'react';
import styles from './DetailsTabHeader.module.scss';
import {ArrowDownward, ArrowUpward} from "@mui/icons-material";
import {COLORS} from "../../../../constants/colors";
import DefaultButton from "../../../../components/Shared/Buttons/DefaultButton";
import {defaultFormatDate, defaultFormatTime, roundToDecimalFormat, timeAgo} from "../../../../utils/format";
import RefreshIcon from "@mui/icons-material/Refresh";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import {formatUnitAndValue} from "../../../../utils/perspective";
import {FORMAT_METRIC_DETAILS} from "../../../../utils/metrics";

const DetailsTabHeader = ({item, update, graphData, loader}) => {
  const updateIntervalRef = useRef(null);

  const [updatedTime, setUpdatedTime] = useState(new Date());
  const [updatedTimeAgo, setUpdatedTimeAgo] = useState(timeAgo(updatedTime, true));

  const handleUpdate = useCallback(() => {
    update();
    setUpdatedTime(new Date());
    setUpdatedTimeAgo(timeAgo(new Date(), true));
    clearInterval(updateIntervalRef.current);
  }, [update]);

  const updateTime = useCallback(() => {
    updateIntervalRef.current = setInterval(() => {
      setUpdatedTimeAgo(timeAgo(updatedTime, true));
    }, 60000);
  }, [updatedTime]);

  useEffect(() => {
    updateTime();
  }, [updateTime]);

  return (
    <div className={styles.container}>
      <div>
        <h2 className={styles.largeTitle}>{formatUnitAndValue(item?.unit, roundToDecimalFormat(graphData.value))}</h2>
        <div className={styles.flexAlignCenter}>
          {item?.increased ? (
            <div className={styles.arrowIconWrapper} style={{background: COLORS.green}}>
              <ArrowUpward style={{color: COLORS.white, fontSize: 14}} />
            </div>
          ) : (
            <div className={styles.arrowIconWrapper} style={{background: COLORS.red}}>
              <ArrowDownward style={{color: COLORS.white, fontSize: 14}} />
            </div>
          )}
          <h4 className={styles.subtitle}>{`${roundToDecimalFormat(graphData.percentageChange) || 0}% vs Last ${FORMAT_METRIC_DETAILS(item?.timeInterval)}`}</h4>
        </div>
      </div>
      <div className={styles.syncWrapper}>
        <DefaultButton
          title={loader ? 'Synchronizing' : 'Sync'}
          rightIcon={(
            <div className={styles.flexAlignCenter}>
              {loader ? (
                <div className={`${styles.spin} ${styles.flexAlignCenter}`}>
                  <RefreshIcon style={{color: COLORS.white}} />
                </div>
              ) : (
                <PublishedWithChangesIcon style={{color: COLORS.white}} />
              )}
            </div>
          )}
          buttonStyles={{width: 200, paddingRight: 48}}
          fontWeight={600}
          onClick={handleUpdate}
        />
        <h5 className={styles.time}>{`Last update: ${updatedTimeAgo}`}</h5>
      </div>
    </div>
  );
};

export default DetailsTabHeader;
