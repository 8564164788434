export const HOME = '/home';
export const VERIFY_USER = '/verify-user/:hash/:email';
export const ACCEPT_INVITATION = '/accept-invitation/:hash';
export const HOME_SEE_ALL_GOALS = '/see-all/:value';
export const HOME_SEE_ALL_PROJECTS_AND_ACTIONS = '/see-all/upcoming/:value';
export const HOME_SEE_ALL_RECENT_UPDATES = '/see-all/upcoming-updates/:value';
export const DASHBOARDS = '/dashboards';
export const PERSPECTIVE_DASHBOARDS = '/perspective-dashboards/:id/strategy-plan/:value';
export const CREATE_WIDGET = '/dashboards/:dashboardId/create-widget/:value';
export const WIDGET_LIST = '/dashboards/create-widget/:dashboardId';
export const SINGLE_DASHBOARD = '/dashboards/:dashboardId';
export const INTEGRATIONS = '/integrations';
export const METRICS = '/metrics';
export const CONNECT_METRIC_WITH_KPI = '/connect-metric-with-kpi/:metricId';
export const STRATIFYAI = '/stratifyai';
export const REPORTS = '/reports';
export const RELEASE_NOTES = '/release-notes';
export const VNC_ACADEMY = '/vnc-academy';
export const VNC_ACADEMY_VIEW = '/vnc-academy/view/:id';
export const PROFILE = '/profile';
export const SETTINGS = '/settings';
export const PRO = '/pro';
export const LOGIN = '/login';
export const REGISTER = '/register';
export const NO_SUBSCRIPTION = '/no-subscription';
export const REDIRECT_TO_HOME = '/redirecting';
export const MICROSOFT_SIGN_IN = '/microsoft-sign-in';
export const FORGOT_PASSWORD = '/forgot-password';
export const STRATEGY_PLAN = '/strategy-plan-view/:id';
export const RESET_PASSWORD = '/reset-password/:code/:email';
export const SCORECARDS = '/scorecards';
export const SCORECARD = '/scorecards/:scorecardId';
export const GROUP_SETTINGS = '/group-settings/:groupId';
export const LINKEDIN = '/linkedin';
export const WORKSPACE = '/workspace';
export const WORKSPACE_TWO_FA = '/workspace/two-factor-authentication';
export const PRICING = '/PRICING';
export const WORKSPACE_CREATE = '/workspace/create';
export const HUB = '/hub/:entity/:id';
export const ALIGNMENT = '/alignment';
export const WORKSHOPS = '/workshops';
export const WEBINAR = '/webinar';
export const MANAGE_CONNECTIONS = '/connections/:type';
export const SINGLE_REPORT = '/reports/:reportId';
export const CREATE_REPORT_WIDGET = '/reports/:reportId/create-widget/:value';
export const REPORT_SNAPSHOT = '/reports/:reportId/snapshots/:folderName/:snapshotName';
export const RENDER_REPORT_SNAPSHOT = '/reports/render/:entity';
export const INTEGRATIONS_GOOGLE_SHEET = '/integrations/google-sheets';
export const INTEGRATIONS_JIRA = '/integrations/jira-redirect';
export const INTEGRATIONS_MICROSOFT_EXCEL = '/integrations/microsoft-excel';
export const NOTIFICATIONS = '/notifications';
export const KANBAN_BOARD = '/kanban-board/:value';
