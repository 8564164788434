import {COLORS} from "../../../../../constants/colors";
import React from "react";
import {ReactComponent as Profile} from '../../../../../assets/icons/profile.svg';
import {ReactComponent as Hub} from '../../../../../assets/icons/hub.svg';
import {ReactComponent as Performance} from '../../../../../assets/icons/performanceIcon.svg';
import {ReactComponent as Trello} from '../../../../../assets/icons/trello.svg';
import {ReactComponent as Microsoft} from '../../../../../assets/icons/microsoft.svg';
import {ReactComponent as JiraIcon} from "../../../../../assets/icons/external-sources/jira-logo.svg";

export const selectTrackingOptions = [
  {
    id: 1,
    title: 'CALCULATIONS',
    titleFontSize: 16,
    options: [
      {
        id: 1,
        title: 'Manually',
        value: 'manually',
        icon: <Profile width={20} height={20} style={{marginRight: 8}}/>,
        subtitle: 'A slider 0% to 100%',
        disabled: false
      },
      {
        id: 2,
        title: 'Milestones',
        value: 'milestone',
        icon: <Performance width={20} height={20} style={{marginRight: 8}}/>,
        subtitle: 'Key steps from 0% to 100% with dates',
        disabled: false
      },
      {
        id: 3,
        title: 'Checklist',
        value: 'checklist',
        icon: <Hub width={20} height={20} style={{marginRight: 8}} color={COLORS.blue}/>,
        subtitle: 'Set of items to be completed',
      },
    ]
  },
  {
    id: 2,
    title: 'INTEGRATIONS',
    titleFontSize: 16,
    options: [
      {
        id: 4,
        title: 'Jira',
        value: 'jira',
        icon: <JiraIcon width={20} height={20} style={{marginRight: 8}} />,
        subtitle: '',
      },
      // {
      //   id: 5,
      //   title: 'Microsoft Project(coming soon)',
      //   value: 'microsoft_project',
      //   icon: <Microsoft width={20} height={20} style={{marginRight: 8}} />,
      //   subtitle: '',
      //   disabled: true
      // },
      // {
      //   id: 6,
      //   title: 'Trello(coming soon)',
      //   value: 'trello',
      //   icon: <Trello width={20} height={20} style={{marginRight: 8}} />,
      //   subtitle: '',
      //   disabled: true
      // },
    ]
  },
  // {
  //   id: 3,
  //   title: `CAN'T FIND WHAT YOU'RE LOOKING FOR?`,
  //   titleFontSize: 12,
  //   options: [
  //     {
  //       id: 7,
  //       title: 'Request a new integration',
  //       value: 'new_integration',
  //       icon: '',
  //       subtitle: '',
  //       disabled: true
  //     }
  //   ]
  // }
]
