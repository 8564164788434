import React, {useCallback, useEffect, useRef, useState} from 'react';
import styles from './SuccessCriteriaComponent.module.scss';
import ProgressBarComponent from "../../../../components/Shared/ProgressBar/ProgressBarComponent";
import CustomDot from "../../../../components/Shared/Dot/CustomDot";
import {getMarksForSlider, goalStatus} from "../../../../utils/perspective";
import {ROW_ITEM_TYPES, TRACKING} from "../../../../constants/strings";
import SliderComponent from "../../../../components/Shared/SliderComponent/SliderComponent";
import UpdateProgressForm from "./UpdateProgressForm";
import {Menu, styled} from "@mui/material";

const StyledMenu = styled(Menu)`
  & .MuiPaper-root {
    border-radius: 8px;
  }

  & .MuiList-root {
    margin: 0;
    padding: 0;
  }
`;

const ItemRowProgressComponent = ({data, isDisabled, handleUpdateProgress, showActions, showStatus = true}) => {
    const [sliderValue, setSliderValue] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const anchorRef = useRef(null);

    const handleSliderValueChange = (e, value) => {
        setSliderValue(value);
    };

    const onUpdateProgress = (values) => {
        if(values.actualValue) {
            setSliderValue(Number(data.inverted ? data.min + data.max - values.actualValue : values.actualValue));
        }
        setAnchorEl(null);
        handleUpdateProgress(values);

    };

    const cancelUpdate = () => {
        setAnchorEl(null);
        setSliderValueFn();
    };

    const onProgressChange = (value) => {
        setSliderValue(Number(value));
    };

    const handleChangeCommitted = (e, value) => {
        setSliderValue(value);
        if (!anchorEl) {
            if (anchorRef.current && !anchorRef.current.contains(e.target)) {
                setAnchorEl(anchorRef.current);
            } else {
                setAnchorEl(anchorEl ? null : e.target);
            }
        }
    };

    const setSliderValueFn = useCallback(() => {
        switch (data.type) {
            case ROW_ITEM_TYPES.KPI:
                setSliderValue(data?.inverted ? data?.min + data?.max - data?.actualValue : data?.actualValue);
                break;
            case ROW_ITEM_TYPES.GOAL:
            case ROW_ITEM_TYPES.PROJECT:
            case ROW_ITEM_TYPES.ACTION:
                setSliderValue(data?.actualProgress);
                break;
            default:
                console.log('no matching type selected');
        }
    }, [data.type, data.actualProgress, data.actualValue]);

    useEffect(() => {
        setSliderValueFn();
    }, [setSliderValueFn]);

    return (
        <div ref={anchorRef} className={styles.container}>
            {((data.type === ROW_ITEM_TYPES.KPI && data.actualValue !== null) || data.type === ROW_ITEM_TYPES.ACTION) ? (
                <div>
                    <div className={ showStatus ? styles.sliderWrapper : styles.sliderWrapperNoStatus}>
                        {
                            !(!showActions && !data.trackingType) && (
                                <SliderComponent
                                    min={data.min}
                                    max={data.max}
                                    value={sliderValue}
                                    onChange={handleSliderValueChange}
                                    onChangeCommitted={handleChangeCommitted}
                                    status={data?.health}
                                    marks={getMarksForSlider(data)}
                                    markLabelFontSize={8}
                                    showRange
                                    showValue
                                    item={data}
                                    inverted={data.inverted}
                                    sliderWidth={showStatus ? 80 : 210}
                                    disabled={data.tracking === TRACKING.JIRA || data.tracking === TRACKING.CHECKLIST || isDisabled }
                                />
                            )

                        }
                    </div>
                    <StyledMenu open={Boolean(anchorEl)} anchorEl={anchorEl}>
                        <UpdateProgressForm
                            min={data.min}
                            max={data.max}
                            progress={data.inverted ? data.min + data.max - sliderValue : sliderValue}
                            status={data.status}
                            expected={data.type === ROW_ITEM_TYPES.KPI ? data.expectedValue : data.expectedProgress}
                            expectedUnit={data.type === ROW_ITEM_TYPES.KPI ? data.unit : 'percentage'}
                            handleOnSubmit={onUpdateProgress}
                            cancelUpdate={cancelUpdate}
                            onProgressChange={onProgressChange}
                            item={data}
                        />
                    </StyledMenu>
                </div>
            ) : (
                <div className={styles.sliderWrapper}>
                    <div className={styles.progressBarContainer}>
                        <ProgressBarComponent item={data} width={80} />
                    </div>
                </div>
            )}
            {
                showStatus &&
                <div className={styles.statusContainer}>
                    {
                        goalStatus(data.health).color && <CustomDot backgroundColor={goalStatus(data.health).color}/>
                    }
                    <h4 className={styles.title}>{goalStatus(data?.health)?.title}</h4>
                </div>
            }
        </div>
    );
};

export default ItemRowProgressComponent;
