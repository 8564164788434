import React, {useEffect, useState} from 'react';
import styles from './TrackingComponent.module.scss'
import {progressScheduleData} from "./progressScheduleData";
import SelectInput from "../Inputs/SelectInput";
import CheckboxInput from "../Inputs/Checkbox";
import {PeriodDays, TRACKING_PERIOD_TYPE} from "../../../constants/strings";



export function ExpectedProgress({item, disableInputs = false, showCheckbox = true, setFormValues, title, clearProp, padding = '20px'}) {
  const findPeriodId = (val) => {
    return progressScheduleData.find(item => item.name.toLowerCase() === val)?.id || 2;
  }

  const [selectedProgressPeriod, setSelectedProgressPeriod] = useState(item?.period ? findPeriodId(item?.period) : 2);
  const [selectedProgressDay, setSelectedProgressDay] = useState('');
  const [sendUpdateCheckbox, setSendUpdateCheckbox] = useState(true);

  useEffect(() => {
    setSelectedProgressPeriod('')
    setSelectedProgressDay('')
    setSendUpdateCheckbox(true)
  },[clearProp])

  useEffect(() => {
    if(item) {
      setSelectedProgressPeriod(findPeriodId(item?.period))
      setSelectedProgressDay(findDayId(item?.day))
      setFormValues({day: item?.day ? item?.day : PeriodDays.FirstDayOfMonth, period: item?.period ? item?.period : TRACKING_PERIOD_TYPE.MONTHLY, notify: item?.notify ? item?.notify : true })
    } else {
      setSelectedProgressPeriod(2)
      setSelectedProgressDay(1)
      setFormValues({day: PeriodDays.FirstDayOfMonth, period: TRACKING_PERIOD_TYPE.MONTHLY, notify: true })
    }
  },[item])

  const onHandleProgressDay = (val) => {
    setSelectedProgressDay(val.target.value)
    setFormValues({day:setDayName(val.target.value)?.value, period: setPeriodName(selectedProgressPeriod), notify: sendUpdateCheckbox})
  }

  const findDayId = (day) => {
    let id = 1;
    progressScheduleData.forEach((item) => {
      item.options.forEach((option) => {
        if(option.value === day) {
         id = option.id
        }
      })
    })
    return id;
  }

  const setPeriodName = (val) => {
    return val ? progressScheduleData.find(item => item.id === val)?.name.toLowerCase() : '';
  }
  const setDayName = (val) => {
    let newItem;
     progressScheduleData.forEach((item) => {
      if(item.id === selectedProgressPeriod){
        newItem =  item.options.find((option) => option.id === val)
      }
    });
     return newItem
  }


  const onHandleProgressPeriod = (val) => {
    setSelectedProgressPeriod(val.target.value)
    setSelectedProgressDay('')
    setFormValues({day:'', period: setPeriodName(val.target.value), notify: sendUpdateCheckbox })
  }

  const onHandleUpdateCheckbox = (val) => {
    setSendUpdateCheckbox(val);
    setFormValues({day:setDayName(selectedProgressDay)?.value, period: setPeriodName(selectedProgressPeriod), notify: val })
  }

    return (
      <div className={styles.manuallyContainer} style={{padding: padding}}>
        <p className={styles.manuallyTitle}>{title}</p>
        <div className={styles.periodView}>
          <div className={styles.selectWrapper}>
            <p>Period</p>
            <SelectInput disabled={disableInputs} options={progressScheduleData} value={selectedProgressPeriod} handleChange={onHandleProgressPeriod}/>
          </div>
          <div className={styles.selectWrapper} >
            <p>Day</p>
            {/*<MultiSelectInput disabled={!selectedProgressPeriod} options={progressScheduleData[(selectedProgressPeriod - 1)]?.options}*/}
            {/*                  value={selectedProgressDay} handleChange={onHandleProgressDay}/>*/}

            <SelectInput disabled={!selectedProgressPeriod || disableInputs} options={selectedProgressPeriod && progressScheduleData[(selectedProgressPeriod - 1)]?.options}
                              value={selectedProgressDay} handleChange={onHandleProgressDay}/>
          </div>
        </div>
        {
          showCheckbox &&
            <div className={styles.checkboxWrapper}>
              <CheckboxInput disabled={disableInputs} handleChangeCheck={onHandleUpdateCheckbox} value={sendUpdateCheckbox}/>
              <p>Send update reminders based on expected progress schedule</p>
            </div>
        }
      </div>
    )

}

export default ExpectedProgress;
