import React, {memo, PureComponent} from 'react';
import styles from './noDataGraph.module.scss';
import {
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import dayjs from "dayjs";
import {COLORS} from "../../constants/colors";

const MetricChartComponent = ({data, width = 500, height = 300}) => {
    class CustomizedAxisTick extends PureComponent {
        render() {
            const { x, y, stroke, payload } = this.props;
            return (
                <g transform={`translate(${x},${y})`}>
                    <text x={15} y={-5} dy={16} fontSize={11} textAnchor="end" fill={COLORS.legendBlack} transform="rotate(-25)">
                        {payload.value}
                    </text>
                </g>
            );
        }
    }

    const CustomTooltip = ({active, payload, label}) => {
        if (active && payload && payload.length) {
            return (
                <div className={styles.customTooltipWrapper}>
                    <p className="label">{`${label}`}</p>
                    <p className="intro">{'Value: ' + payload[0]?.value}</p>
                </div>
            );
        }

        return null;
    };

    const CustomizedYAxisTick = (props) => {
        const { x, y, payload, anchor, color } = props;

        return (
            <text x={x} y={y} textAnchor={anchor} fill={color}>
                {payload.value}
            </text>
        );
    };

    return (
        <div className={styles.wrapper}>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    width={width} height={height}
                    data={data}
                    margin={{
                        top: 5,
                        right: 20,
                        bottom: 5,
                    }}
                >
                    <XAxis
                        type={'category'}
                        domain={['auto', 'auto']}
                        tick={<CustomizedAxisTick/>}
                        dataKey="date"
                    />
                    <YAxis
                        tick={<CustomizedYAxisTick anchor={'end'} color={COLORS.legendBlack} />}
                    />
                    <Tooltip content={<CustomTooltip/>}/>
                    <Legend />
                    <Line type="linear" dataKey="value" stroke="#82ca9d" />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};
export default memo(MetricChartComponent);
