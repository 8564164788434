import React, {memo, useEffect, useState} from 'react';
import styles from './DetailsTabComponent.module.scss';
import DetailsTabHeader from "./DetailsTabHeader";
import MetricDetails from "./MetricDetails";
import MetricChartComponent from "../../../../components/Graphs/MetricChartComponent";

const DetailsTabComponent = ({item, update, loader, graphData}) => {

  return (
    <div className={styles.container}>
      {graphData && (
        <div className={styles.mainContainer}>
          <DetailsTabHeader graphData={graphData} item={item} update={update} loader={loader} />
            <div>
              <MetricChartComponent data={graphData.updates} item={item} onChartClick={() => {}} />
            </div>
          <div className={styles.metricDetailsContainer}>
            <MetricDetails item={item} />
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(DetailsTabComponent);
