import React, {memo, useEffect, useState} from 'react';
import styles from './KpiModalContentComponent.module.scss';
import {Field, Form, Formik} from "formik";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import {createKpiSchema} from "../../../../../validation/scorecard";
import InputField from "../../../../../components/Shared/Inputs/InputField";
import TextareaField from "../../../../../components/Shared/Inputs/TextareaField";
import SelectField from "../../../../../components/Shared/Inputs/SelectField";
import {helperService} from "../../../../../services/HelperService";
import AutocompleteField from "../../../../../components/Shared/Inputs/AutocompleteField";
import DefaultButton from "../../../../../components/Shared/Buttons/DefaultButton";
import TrackingComponent from "../../../../../components/Shared/TrackingComponent/TrackingComponent";
import ContributesToComponent from "../../../../../components/Shared/ContributesToComponent";
import {useSelector} from "react-redux";
import {itemAndParentForActionSelector} from "../../../../../store/helper/selectors";
import {ROW_ITEM_TYPES} from "../../../../../constants/strings";
import {selectTrackingOptions} from "./trackingData";
import {
  getScorecardId,
  setParentDataForContributesToComponent
} from "../../../../../utils/setDataForContributesToComponent";
import {ReactComponent as AlignIcon} from "../../../../../assets/icons/share.svg";
import {ReactComponent as ArrowDown} from "../../../../../assets/icons/arrowDown.svg";
import usersService from "../../../../../services/UsersService";
import Checkbox from "@mui/material/Checkbox";
import {terminologySelector} from "../../../../../store/auth/selectors";

const KpiModalContentComponent = ({metricId, trackingTypeRequired = false,onFormSubmit, onCancel, canContributesToEditParent, openTracking = false, dateSameAsGoalOption = false}) => {
    const [showSharingWith, setShowSharingWith] = useState(false);
    const itemAndParentForAction = useSelector(itemAndParentForActionSelector());
    const [userOptions, setUserOptions] = useState([]);
    const [ownerIdValue, setOwnerIdValue] = useState(itemAndParentForAction?.item?.owner?.id || null);
    const [datesSameAsGoal, setDatesSameAsGoal] = useState(false);

    const terminology = useSelector(terminologySelector());

    const handleStartDateChange = (newDate, setFieldValue) => {
        setFieldValue('startDate', newDate);
    }

    const handleEndDateChange = (newDate, setFieldValue) => {
        setFieldValue('endDate', newDate);
    }

    const handleOnSubmit = async (values) => {
        let obj = {
            ...values,
            owners: values.owners.map(item => item.id)
        }
        onFormSubmit(obj)
    }

    const onBoxShow = () => {
        setShowSharingWith(!showSharingWith);
    }

    const getUsers = async () => {
      try {
        let scorecardId = getScorecardId(itemAndParentForAction?.parent);
        if (scorecardId) {
          const res = await usersService.getUsersByScorecard(scorecardId);
          const owner = res.find((user) => user.id === ownerIdValue);
          if (itemAndParentForAction?.item?.owner && !owner) {
            res.push({...itemAndParentForAction?.item?.owner, disabled: true});
          }
          setUsersForOptions(res);
        }
      } catch (e) {
        console.log('err', {e});
      }
    };

    const setUsersForOptions = (users) => {
      setUserOptions(users.map((user) => {
        return {
          id: user.id,
          value: user.id,
          title: user.title ? user.title : user.name,
          subtitle: user.subtitle ? user.subtitle : user.email,
          disabled: user.id === ownerIdValue,
          image: user.image ? user.image : user.photo,
        }
      }))
    }

    useEffect(() => {
       getUsers()
    }, [itemAndParentForAction])

    useEffect(() => {
      setUsersForOptions(userOptions)
    }, [ownerIdValue])

  const setNewOwner = (id, values, setValues) => {
    setOwnerIdValue(id)
    let newArr = values.owners.filter((owner) => owner.id !== id)
    setValues({...values, owners: [...newArr], ownerId: id})
  }

    return (
        <div>
            <Formik
                initialValues={{
                    id: itemAndParentForAction?.item?.id,
                    name: itemAndParentForAction?.item?.name || '',
                    description: itemAndParentForAction?.item?.description || '',
                    ownerId: itemAndParentForAction?.item?.owner?.id || null,
                    startDate: itemAndParentForAction?.item?.startDate ? dayjs(itemAndParentForAction?.item.startDate) : null,
                    endDate: itemAndParentForAction?.item?.endDate ? dayjs(itemAndParentForAction?.item.endDate) : null,
                    themeId: itemAndParentForAction?.item?.themeId || null,
                    owners: itemAndParentForAction?.item?.owners?.map((user) => {
                            return {
                                id: user.id,
                                value: user.id,
                                title: user.name,
                                subtitle: user.email,
                                image: user.photo
                            };
                        })
                        || [],
                    parentType: itemAndParentForAction?.parent?.type,
                    parentId: itemAndParentForAction?.parent?.id,
                    upperTarget: itemAndParentForAction?.item?.upperTarget,
                    lowerTarget: itemAndParentForAction?.item?.lowerTarget,
                    targetType: itemAndParentForAction?.item?.targetType,
                    min: itemAndParentForAction?.item?.min,
                    max: itemAndParentForAction?.item?.max,
                    unit: itemAndParentForAction?.item?.unit,
                    trackingType: itemAndParentForAction?.item?.trackingType,
                    goalId: itemAndParentForAction.parent.id || '',
                    metricIds: itemAndParentForAction?.item?.metrics?.map(item => item.id)
                }}
                onSubmit={handleOnSubmit}
                validationSchema={createKpiSchema({trackingTypeRequired:trackingTypeRequired})}
                enableReinitialize={true}
                validateOnChange={false}
            >
                {({setFieldValue, errors, values, validateForm, setValues}) => {
                    return (
                        <Form>
                            <div className={styles.container}>
                                <div className={styles.formWrapper}>
                                    <Field
                                        component={InputField}
                                        name={'name'}
                                        setFieldValue={setFieldValue}
                                        errors={errors}
                                        placeholder={`${terminology.kpi} Name`}
                                        label={`${terminology.kpi} Name`}
                                    />
                                    <Field
                                        component={TextareaField}
                                        name={'description'}
                                        setFieldValue={setFieldValue}
                                        errors={errors}
                                        placeholder={`${terminology.kpi} Description`}
                                        label={`${terminology.kpi} Description`}
                                    />
                                    <Field
                                        component={SelectField}
                                        name={'ownerId'}
                                        setFieldValue={setFieldValue}
                                        errors={errors}
                                        placeholder={{
                                            value: `${terminology.kpi} Owner`,
                                            icon: require('../../../../../../src/assets/icons/profile-owner.png'),
                                        }}
                                        label={`${terminology.kpi} Owner`}
                                        options={{
                                            options: userOptions,
                                            imageKey: 'photo',
                                            defaultImage: require('../../../../../../src/assets/icons/profile-owner.png'),
                                            titleKey: 'name',
                                            subtitleKey: 'email',
                                        }}
                                        getFieldVal={(val) => setNewOwner(val, values, setValues)}
                                    />
                                    {
                                        dateSameAsGoalOption &&
                                        <div className={styles.dateWithSameAsGoal}>
                                            <p>Dates</p>
                                            <div className={styles.checkboxWrapper}>
                                                <p>Same as {terminology.goal}</p>
                                                <Checkbox onClick={() => setDatesSameAsGoal(!datesSameAsGoal)} />
                                            </div>
                                        </div>
                                    }
                                    <div className={styles.dateInputsWrapper}>
                                        <div className={styles.singleDateInputWrapper}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker name={'startDate'} value={datesSameAsGoal ? dayjs(itemAndParentForAction.parent.startDate) : values.startDate || null}
                                                            onChange={(e) => handleStartDateChange(e, setFieldValue)}
                                                            label="Start Date"
                                                            disabled={datesSameAsGoal}
                                                            minDate={dayjs(itemAndParentForAction.parent.startDate)}
                                                            maxDate={dayjs(itemAndParentForAction.parent.endDate)}/>
                                            </LocalizationProvider>
                                            <p className={styles.errorMessage}>{errors.startDate && errors.startDate}</p>
                                        </div>
                                        <div>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker name={'endDate'} value={datesSameAsGoal ? dayjs(itemAndParentForAction.parent.endDate) : values.endDate || null}
                                                            onChange={(e) => handleEndDateChange(e, setFieldValue)}
                                                            label="End Date"
                                                            disabled={datesSameAsGoal}
                                                            minDate={values.startDate || dayjs(itemAndParentForAction.parent.startDate)}
                                                            maxDate={dayjs(itemAndParentForAction.parent.endDate)}/>
                                            </LocalizationProvider>
                                            <p className={styles.errorMessage}>{errors.endDate && errors.endDate}</p>
                                        </div>
                                    </div>
                                    <Field
                                        component={SelectField}
                                        name={'themeId'}
                                        setFieldValue={setFieldValue}
                                        errors={errors}
                                        placeholder={{
                                            value: 'Theme',
                                        }}
                                        label={'Theme'}
                                        options={{
                                            apiService: helperService,
                                            apiServiceMethod: 'getThemes',
                                            titleKey: 'name',
                                            colorKey: 'color'
                                        }}
                                    />
                                    <div className={styles.wrapper} style={{height: showSharingWith ? 'auto' : '70px'}}>
                                        <div className={styles.titleWrapper}>
                                            <div className={styles.leftTitleWrapper}>
                                                <AlignIcon/>
                                                <p className={styles.titleText}>Sharing with</p>
                                            </div>
                                            <button type={'button'} onClick={onBoxShow} className={styles.arrowDownBtn} style={{transform: showSharingWith ? 'rotate(180deg)' : 'rotate(0deg)'}}>
                                                <ArrowDown/>
                                            </button>
                                        </div>
                                        <Field
                                            component={AutocompleteField}
                                            name={'owners'}
                                            setFieldValue={setFieldValue}
                                            errors={errors}
                                            placeholder={{
                                                value: 'Start typing name or email',
                                            }}
                                            label={`Add teammates to collaborate with this ${terminology.kpi}. Search for a user by name or email.`}
                                            options={{options: userOptions, disabledText: 'Owner', subtitleKey: 'email', imageKey: 'photo', defaultImage: require('../../../../../../src/assets/icons/profile-owner.png')}}
                                        />
                                    </div>
                                    {selectTrackingOptions &&
                                        <TrackingComponent
                                          metricId={metricId}
                                          formValues={values} validateForm={validateForm}
                                          setValues={setValues}
                                            openTracking={openTracking}
                                            item={itemAndParentForAction.item} errors={errors} itemType={ROW_ITEM_TYPES.KPI} setFieldValue={setFieldValue}
                                                           selectTitle={`How do you want to track this ${terminology.kpi}`}
                                                           selectOptions={selectTrackingOptions}/>}

                                    <ContributesToComponent
                                      canEditParent={canContributesToEditParent}
                                      item={itemAndParentForAction?.item}
                                        parent={setParentDataForContributesToComponent(itemAndParentForAction?.parent, itemAndParentForAction?.parent?.strategyPlanName)}
                                        showForms={false}
                                        setFormikField={(val) => setFieldValue('sharedGoalIds', val)}/>
                                </div>
                                <div className={styles.fixedSubmitWrapper}>
                                    <DefaultButton title={'Cancel'} type={'button'} secondary onClick={onCancel} />
                                    <DefaultButton title={'Save'} type={'submit'} buttonStyles={{marginLeft: '16px'}} />
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default memo(KpiModalContentComponent);
