import React, {Fragment, useCallback} from 'react';
import styles from './SideBar.module.scss';
import {Divider} from "@mui/material";
import {ReactComponent as Arrow} from '../../assets/icons/side-bar/side-bar-arrow.svg';
import {ReactComponent as Logo} from '../../assets/icons/Logo.svg';
import {NavLink} from "react-router-dom";
import {BOTTOM_SIDE_BAR_ITEMS, TOP_SIDE_BAR_ITEMS} from "./sidebarConstants";
import {useDispatch, useSelector} from "react-redux";
import {logout, setSidebarState} from "../../store/auth/actions";
import {makeSelectUser, sidebarStateSelector, terminologySelector} from "../../store/auth/selectors";
import {COLORS} from "../../constants/colors";
import TooltipComponent from "../Shared/Tooltip/TooltipComponent";
import {ReactComponent as Pro} from '../../assets/icons/side-bar/pro-icon.svg';
import {Role, SUBSCRIPTION_STATUS} from "../../constants/strings";

function SideBar({isHidden = false}) {
    const dispatch = useDispatch();

    const sidebarState = useSelector(sidebarStateSelector());
    const user = useSelector(makeSelectUser());
    const terminology = useSelector(terminologySelector());

    const hideMenu = (e) => {
        if (sidebarState === 'hidden') {
            dispatch(setSidebarState('show'));
            localStorage.setItem('sidebar', 'show');
        } else {
            dispatch(setSidebarState('hidden'));
            localStorage.setItem('sidebar', 'hidden');
        }
    };

    const sideItem = (item) => {
        return (
              <div className={styles.sideBarItemDiv}>
                  {sidebarState === 'show' ? (
                      <Fragment>
                          {item.icon}
                          <p className={`${styles.labelText} ${item.disabled && styles.disabledLink}`}>{item.title}</p>
                      </Fragment>
                  ) : (
                      <TooltipComponent title={item.title} placement={'right'}>
                          {item.icon}
                      </TooltipComponent>
                  )}
              </div>
        )
    }

    const handleClick = useCallback((val) => {
        localStorage.removeItem('strategyPlanSelectedTab')
        if (val === 'login') {
            dispatch(logout());
        }
    }, [dispatch]);

    const smallPro = () => {
        return <a href={'/pricing'} className={styles.proItemSmall}>
            <Pro/>
        </a>;
    }

    const bigPro = () => {
        return <div className={styles.proItemBig}>
            <div className={styles.proItemSmall} style={{borderRadius: '56px', width: '56px', height: '56px'}}>
                <Pro/>
            </div>
            <span className={styles.upgradeText}>Upgrade to Premier</span>
            {/*<span className={styles.getText}>Get 1 month free and unlock Pro features</span>*/}
            <a href={'/pricing'} className={styles.upgradeButton}>Upgrade</a>
        </div>;
    }

    const proItem = () => {
        return (
            <div style={{padding: '16px'}} >
                {sidebarState === 'hidden' ? smallPro() : bigPro()}
            </div>

        )
    }

    let subscribed = !(user.organization.subscriptionStatus !== SUBSCRIPTION_STATUS.SUBSCRIBED && user.organization.subscriptionStatus !== SUBSCRIPTION_STATUS.TRIAL)

    return (
        <div className={styles.wrapper} style={{width: sidebarState === 'hidden' ? '96px' : '260px'}}>
            <div className={styles.logoWrapper} style={{justifyContent: sidebarState === 'hidden' ? 'center' : 'flex-start'}}>
                <Logo/>
                {sidebarState === 'show' && <span className={styles.logoText}>StratifyPro</span>}
                <button className={sidebarState === 'hidden' ? styles.arrowButtonHidden : styles.arrowButton} onClick={hideMenu}>
                    <Arrow color={sidebarState === 'hidden' ? 'white' : COLORS.lightGray01} width={sidebarState === 'hidden' ? 12 : 20}
                           height={sidebarState === 'hidden' ? 12 : 20}/>
                </button>
            </div>
            <div className={styles.sideBarWrapper}>
                <div>
                    {(user.organization.subscriptionStatus !== SUBSCRIPTION_STATUS.SUBSCRIBED && user.organization.subscriptionStatus !== SUBSCRIPTION_STATUS.TRIAL) ? [] : TOP_SIDE_BAR_ITEMS({terminology}).map((item, index) => (
                        <NavLink
                            onClick={() => handleClick(item.path)}
                            key={item.id}
                            to={item.path ? item.path : '#'}
                            style={{
                                paddingLeft: sidebarState === 'hidden' ? 20 : 32,
                                width: sidebarState === 'hidden' ? 96 : '100%',
                                justifyContent: sidebarState === 'hidden' ? 'center' : 'flex-start',
                            }}
                            className={`${styles.link} ${item.disabled && styles.disabledLink}`}
                            activeClassName={item.path ? styles.active : null}
                        >
                            {sideItem(item)}
                        </NavLink>
                    ))}
                </div>
                <div style={{marginBottom: '16px'}}>
                    {
                        (user.role === Role.ADMIN && user.organization.subscriptionStatus !== SUBSCRIPTION_STATUS.TRIAL) && (
                            proItem()
                        )
                    }
                    {BOTTOM_SIDE_BAR_ITEMS({user, terminology, subscribed}).map((item) => {
                        return (
                            <div key={item.id}>
                                {item.isProfile && <Divider className={styles.dividerStyle} variant='middle' flexItem/>}
                                <NavLink
                                    onClick={() => handleClick(item.path)}
                                    to={item.path ? item.path : '#'}
                                    style={{
                                        paddingLeft: sidebarState === 'hidden' ? 20 : item.isProfile ? 24 : 32,
                                        width: sidebarState === 'hidden' ? 96 : '100%',
                                        justifyContent: sidebarState === 'hidden' ? 'center' : 'flex-start'
                                    }}
                                    className={styles.link}
                                    activeClassName={item.path ? styles.active : null}
                                >
                                    {sideItem(item)}
                                </NavLink>
                            </div>

                        )
                    })}
                </div>
            </div>
        </div>
    );
}


export default SideBar;
