import React, {memo, useCallback, useEffect, useState} from 'react';
import styles from './ChartWithProgressComponent.module.scss';
import ChartComponent from "./ChartComponent/ChartComponent";
import ProgressBarComponent from "../ProgressBar/ProgressBarComponent";
import SliderComponent from "../SliderComponent/SliderComponent";
import UpdateProgressForm from "../../../containers/StrategyPlanPage/ItemRowComponent/Components/UpdateProgressForm";
import {Menu, styled} from "@mui/material";
import {ROW_ITEM_TYPES, TRACKING} from "../../../constants/strings";
import {updateKpiSaga} from "../../../store/kpis/actions";
import {useDispatch, useSelector} from "react-redux";
import {strategyPlanSelector} from "../../../store/strategyPlan/selectors";
import {formatUnit, getMarksForSlider} from "../../../utils/perspective";
import {rightModalSelector} from "../../../store/helper/selectors";
import {updateActionSaga} from "../../../store/action/actions";
import MilestoneChartComponent from "./MilestoneChartComponent/MilestoneChartComponent";
import DefaultButton from "../Buttons/DefaultButton";

const StyledMenu = styled(Menu)`
  & .MuiPaper-root {
    border-radius: 8px;
  }

  & .MuiList-root {
    margin: 0;
    padding: 0;
  }
`;

const ChartWithProgressComponent = ({
                                        data,
                                        showHeader = true,
                                        showSlider = false,
                                        showRange = false,
                                        page,
                                        hideProgress = false
                                    }) => {
    const dispatch = useDispatch();
    const [sliderValue, setSliderValue] = useState(null);
    const [formattedDatesState, setFormattedDatesState] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showExpanded, setShowExpanded] = useState(false);
    const strategyPlan = useSelector(strategyPlanSelector());
    const rightModal = useSelector(rightModalSelector());
    const formatUpdates = (arr) => {
        let sortedDates = [...arr];

        // sortedDates.sort((date1, date2) => date1.id - date2.id);
        sortedDates.sort((date1, date2) => {
            const date1time = new Date(date1.createdAt).getTime();
            const date2time = new Date(date2.createdAt).getTime();
            return date1time - date2time;
        });

        const uniqueDatesMap = sortedDates.reduce((map, entry) => {
            const date = entry.createdAt.split('T')[0];

            map.set(date, entry);
            return map;
        }, new Map());


        const uniqueDataArray = Array.from(uniqueDatesMap.values());
        setFormattedDatesState(uniqueDataArray)
    }

    useEffect(() => {
        if (data.updates.length > 0) {
            formatUpdates(data.updates)
        }
    }, [data]);

    const handleSliderValueChange = (e) => {
        setSliderValue(e.target.value);
    };

    const handleClick = (e) => {
        if (!anchorEl) {
            setAnchorEl(anchorEl ? null : e.currentTarget);
        }
    };

    const setSliderValueFn = useCallback(() => {
        switch (data.type) {
            case ROW_ITEM_TYPES.KPI:
                setSliderValue(data?.inverted ? data?.min + data?.max - data?.actualValue : data?.actualValue);
                break;
            case ROW_ITEM_TYPES.GOAL:
            case ROW_ITEM_TYPES.PROJECT:
            case ROW_ITEM_TYPES.ACTION:
                setSliderValue(data?.actualProgress);
                break;
            default:
                console.log('no matching type selected');
        }
    }, [data.type, data.actualProgress, data.actualValue]);

    const handleUpdateProgress = (values) => {
        switch (data.type) {
            case ROW_ITEM_TYPES.KPI:
                dispatch(updateKpiSaga({
                    data: values,
                    kpiId: data?.id,
                    scorecardId: strategyPlan?.id,
                    goalId: rightModal.goalId,
                    page,
                    fromDetailsTab: true,
                    entity: rightModal.entity,
                    entityId: rightModal.entityId,
                    projectId: rightModal.projectId,
                    metrics: data.metrics,
                }));
                break;
            case ROW_ITEM_TYPES.ACTION:
                dispatch(updateActionSaga({
                    data: values,
                    actionId: data?.id,
                    scorecardId: strategyPlan?.id,
                    goalId: rightModal.goalId,
                    page,
                    fromDetailsTab: true,
                    entity: rightModal.entity,
                    entityId: rightModal.entityId,
                    projectId: rightModal.projectId,
                }));
                break;
            default:
                console.log('no matching type selected');
        }
    };

    const getProgress = () => {
        switch (data.type) {
            case ROW_ITEM_TYPES.KPI:
                return {
                    actual: data.actualValue,
                    expected: data.expectedValue,
                    expectedPosition: data.inverted ? 100 - 100 * (data.expectedValue - data.min) / (data.max - data.min) : 100 * (data.expectedValue - data.min) / (data.max - data.min),
                    expectedUnit: formatUnit(data.unit),
                };
            case ROW_ITEM_TYPES.GOAL:
            case ROW_ITEM_TYPES.PROJECT:
            case ROW_ITEM_TYPES.ACTION:
                return {
                    actual: data.actualProgress,
                    expected: data.expectedProgress,
                    expectedPosition: data.expectedProgress,
                    expectedUnit: '%',
                };
            default:
                console.log('no matching type selected');
        }
    };

    const onUpdateProgress = (values) => {
        if (values.actualValue) {
            setSliderValue(Number(data.inverted ? data.min + data.max - values.actualValue : values.actualValue));
        }
        setAnchorEl(null);
        handleUpdateProgress(values);
    };

    const cancelUpdate = () => {
        setAnchorEl(null);
        setSliderValueFn();
    };

    const onProgressChange = (value) => {
        setSliderValue(Number(value));
    };

    const handleChangeCommitted = (e, value) => {
        const anchorDiv = document.getElementById('anchor-div');
        setSliderValue(value);
        if (!anchorEl) {
            setAnchorEl(anchorEl ? null : anchorDiv);
        }
    };

    useEffect(() => {
        setSliderValueFn();
    }, [setSliderValueFn]);

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.sliderWrapper}>
                {!showSlider ? (
                    <div>
                        {
                            !hideProgress && (
                                <ProgressBarComponent
                                    hideValue={true}
                                    showHeaderBadge={data.health ? data.health : false}
                                    header
                                    // TODO don't touch that. Will be used when BE comes
                                    // flags={[{id: 1, expectedProgress: 70}, {id: 1, expectedProgress: 85}]}
                                    headerValue={getProgress().actual}
                                    item={data}
                                />
                            )
                        }
                    </div>
                ) : (
                    <div id='anchor-div'>
                        <SliderComponent
                            marks={getMarksForSlider(data, !(data.tracking === TRACKING.MILESTONE))}
                            min={data.min}
                            max={data.max}
                            expectedProgress={getProgress().expected}
                            expectedProgressPosition={getProgress().expectedPosition}
                            expectedProgressUnit={getProgress().expectedUnit}
                            showHeader={showHeader}
                            showHeaderStatus
                            onChange={handleSliderValueChange}
                            onClick={handleClick}
                            status={data?.health}
                            value={sliderValue}
                            onChangeCommitted={handleChangeCommitted}
                            showRange={showRange}
                            item={data}
                            inverted={data.inverted}
                            disabled={data.tracking === TRACKING.JIRA || data.tracking === TRACKING.CHECKLIST}
                        />
                        <StyledMenu open={Boolean(anchorEl)} anchorEl={anchorEl}>
                            <UpdateProgressForm
                                min={data.min}
                                max={data.max}
                                progress={data.inverted ? data.min + data.max - sliderValue : sliderValue}
                                status={data?.status}
                                expected={getProgress().expected}
                                expectedUnit={data.type === ROW_ITEM_TYPES.KPI ? data.unit : 'percentage'}
                                handleOnSubmit={onUpdateProgress}
                                cancelUpdate={cancelUpdate}
                                onProgressChange={onProgressChange}
                                item={data}
                            />
                        </StyledMenu>
                    </div>
                )}
            </div>
            {data.updates && data?.updates?.length > 0 && (
                <div className={styles.graphWrapper} style={{height: data.tracking && data.tracking === 'milestone' ? 'auto' : '300px'}}>
                    {
                        data.tracking && data.tracking === 'milestone' ?
                            <MilestoneChartComponent showExpanded={showExpanded} item={data}/> :
                            <ChartComponent data={formattedDatesState} item={data} onChartClick={() => {
                            }}/>
                    }
                </div>
            )}
        </div>
    );
};

export default memo(ChartWithProgressComponent);
